// src/helpers/generateDriftAndRotation.js
export const generateUniqueDrift = (lastDriftDirection) => {
    let driftX, driftY;
    let driftDirection;
  
    do {
      driftX = (Math.random() * 30 + 5) * (Math.random() > 0.5 ? 1 : -1) + 'vw';
      driftY = (Math.random() * 20 + 5) * (Math.random() > 0.5 ? 1 : -1) + 'vh';
  
      driftDirection = {
        x: Math.sign(parseFloat(driftX)),
        y: Math.sign(parseFloat(driftY)),
      };
    } while (
      driftDirection.x === lastDriftDirection.x &&
      driftDirection.y === lastDriftDirection.y
    );
  
    const rotation = Math.random() * 40 - 20 + 'deg';
    return { driftX, driftY, rotation, driftDirection };
  };
  