
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import './Common.css'

const Header = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
      AuthService.signOut()
        .then(() => {
          navigate('/');
        })
        .catch((error) => {
          console.error('Error logging out:', error);
        });
    };

    return (
        <div className="header">
            <div className="logo-container">
                <img className="logo" src="/images/logo.png" alt="Logo" />
            </div>
            {AuthService.isLoggedIn() && (
                <button onClick={handleLogout} className="logout-link">Log out</button>
            )}
        </div>
    );
}

export default Header;