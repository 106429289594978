// src/services/EventService.js
import { ref as dbRef, query, orderByChild, equalTo, onValue, get } from 'firebase/database';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../firebaseConfig';

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const EventService = {
    eventsForUser: async (userId) => {
        const eventsRef = dbRef(database, 'events');
        const userEventsQuery = query(eventsRef, orderByChild('hostUserId'), equalTo(userId));
        return get(userEventsQuery)
            .then(async (snapshot) => {
                const eventsData = [];
                if (snapshot.exists()) {
                    snapshot.forEach((childSnapshot) => {
                      const event = { id: childSnapshot.key, ...childSnapshot.val() };
                      eventsData.push(event);
                    });
                }
                return eventsData;
            })
            .catch((error) => {
              console.error('Error fetching events:', error);
            })
    },

    observe: (eventId, onUpdate) => {
        const eventRef = dbRef(database, `events/${eventId}`);

        const unsubscribe = onValue(eventRef, (snapshot) => {
            if (snapshot.exists()) {
                const updatedEvent = snapshot.val();
                onUpdate(updatedEvent);
            }
        });

        return unsubscribe;
    },

    fetchEventByCode: async (code) => {
      try {
        const eventsRef = dbRef(database, 'events');
        const eventQuery = query(eventsRef, orderByChild('code'), equalTo(code));
        const snapshot = await get(eventQuery);
  
        if (snapshot.exists()) {
          const eventKey = Object.keys(snapshot.val())[0];
          const event = snapshot.val()[eventKey];
          return { id: eventKey, ...event };
        } else {
          throw new Error('Event not found');
        }
      } catch (error) {
        console.error('Error fetching event by code:', error);
        throw error;
      }
    },

    fetchPhotoUrls: async (eventId) => {
        try {
            const eventRef = dbRef(database, `events/${eventId}`);
            const snapshot = await get(eventRef);

            if (snapshot.exists()) {
                const eventData = snapshot.val();
                const photoIds = eventData.photoIds;

                if (Array.isArray(photoIds)) {
                    const photoUrls = await EventService.fetchUrlsForPhotoIds(eventId, photoIds);

                    return shuffleArray(photoUrls);
                } else {
                    console.log('No photoIds array found for this event.');
                }
            } else {
                console.log('Event not found.');
            }
        } catch (error) {
            console.error('Error fetching event or photos:', error);
        }
        return [];
    },

    fetchUrlsForPhotoIds: async (eventId, photoIds) => {
        try {
            const photoUrls = await Promise.all(
                photoIds.map(async (photoId) => {
                    const photoRef = storageRef(storage, `${eventId}/${photoId}.jpeg`);
                    try {
                        return await getDownloadURL(photoRef);
                    } catch (error) {
                        console.error(`Error fetching photo ${photoId}:`, error);
                        return null;
                    }
                })
            );
            return photoUrls.filter((url) => url !== null);
        } catch (error) {
            console.error('Error fetching photo URLs:', error);
            return [];
        }
    },

    shuffleArray: (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },
};

export default EventService;
