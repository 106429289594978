import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database'; 

const firebaseConfig = {
    apiKey: "AIzaSyC1LjZDKfJtzYED5cXPFEbbeqjhMvSKO0g",
    authDomain: "picstax-80007.firebaseapp.com",
    databaseURL: "https://picstax-80007-default-rtdb.firebaseio.com",
    projectId: "picstax-80007",
    storageBucket: "picstax-80007.appspot.com",
    messagingSenderId: "821830664223",
    appId: "1:821830664223:web:5e9f049775e258ab93d52a",
    measurementId: "G-893RME7KGR"
};

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export const auth = getAuth(app);
export const storage = getStorage(app);
export const database = getDatabase(app);
export default app;