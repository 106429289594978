// src/Slideshow.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { generateUniqueDrift } from './helpers/generateDriftAndRotation';
import EventService from '../services/EventService';
import PhotoService from '../services/PhotoService';
import AuthService from '../services/AuthService';
import Photo from './components/Photo';
import './Slideshow.css';

const Slideshow = () => {
  const { eventCode } = useParams();
  const [eventId, setEventId] = useState(null);
  const [photoUrls, setPhotoUrls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [lastDriftDirection, setLastDriftDirection] = useState({ x: 0, y: 0 });
  const [isFullscreen, setIsFullscreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = AuthService.onAuthStateChange((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchEventAndPhotos = async () => {
      try {
        if (!isAuthenticated) return;
        const userId = AuthService.getUserId();
        if (!userId) throw new Error('User not authenticated');
        const event = await EventService.fetchEventByCode(eventCode);

        if (event.hostUserId !== userId) {
          throw new Error('Access denied: You are not the host of this event');
        }
        setEventId(event.id)
        const urls = await EventService.fetchPhotoUrls(event.id);
        setPhotoUrls(urls);
        setLoading(false);
      } catch (err) {
        console.error('Error loading slideshow:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchEventAndPhotos();

    const unsubscribe = EventService.observe(eventId, async (updatedEvent) => {
      if (updatedEvent.photoIds) {
        const newPhotoIds = updatedEvent.photoIds;
        const newPhotoUrls = newPhotoIds.filter((id) => !photoUrls.includes(`${eventId}/${id}.jpeg`));

        if (newPhotoUrls.length > 0) {
          const fetchedUrls = await EventService.fetchUrlsForPhotoIds(eventId, newPhotoIds);
          setPhotoUrls((prevUrls) => {
            const updatedUrls = [...prevUrls];
            updatedUrls.splice((currentIndex % photoUrls.length) + 1, 0, ...fetchedUrls);
            
            if (updatedUrls.length > 50) {
              return updatedUrls.slice(-50);
            }
            return updatedUrls;
          });
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [isAuthenticated, eventCode]);

  useEffect(() => {
    if (photoUrls.length > 0) {
      const interval = setInterval(() => {
        const nextPhotoIndex = currentIndex + 1;
        const nextPhotoUrl = photoUrls[nextPhotoIndex % photoUrls.length];
        const { driftX, driftY, rotation, driftDirection } = generateUniqueDrift(lastDriftDirection);
        const zIndex = nextPhotoIndex

        PhotoService.preloadImage(nextPhotoUrl).then(() => {
            setPhotos((prev) => [
              ...prev,
              { url: nextPhotoUrl, driftX, driftY, rotation, zIndex }
            ]);
            setCurrentIndex(nextPhotoIndex);
            setLastDriftDirection(driftDirection);
        });
      }, 10000);
  
      return () => clearInterval(interval); // Clear interval on cleanup
    }
  }, [photoUrls, photos, lastDriftDirection]);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const goBackToEvents = () => {
    navigate('/events');
  };

  if (error) return <p>{error}</p>;

  return (
    <div className="slideshow-container">
      {loading ? (
        <p>Loading slideshow...</p>
      ) : (
        <>
          {photos.map((photo, index) => (
            <Photo
            key={index}
            url={photo.url}
            driftX={photo.driftX}
            driftY={photo.driftY}
            rotation={photo.rotation}
            zIndex={photo.zIndex}
            isAnimated={photo.zIndex === currentIndex}
          />
          ))}

          {!isFullscreen && (
            <span className="material-icons fullscreen-icon" onClick={toggleFullScreen}>
              fullscreen
            </span>
          )}

          {isFullscreen && (
            <span className="material-icons close-icon" onClick={toggleFullScreen}>
              close
            </span>
          )}

          <span className="material-icons back-icon" onClick={goBackToEvents}>
            arrow_back
          </span>
        </>
      )}
    </div>
  );
};

export default Slideshow;
