// src/Events.jsx
import React, { useEffect, useState } from 'react';
import EventService from '../services/EventService';
import PhotoService from '../services/PhotoService';
import AuthService from '../services/AuthService';
import { useNavigate } from 'react-router-dom';
import './Events.css';
import Header from '../common/Header'


const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const unsubscribe = AuthService.onAuthStateChange((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (!isAuthenticated) return;
    
    const userId = AuthService.getUserId();
    EventService.eventsForUser(userId)
      .then(async (eventsData) => {
          const eventsWithPhotos = await Promise.all(
            eventsData.map(async (event) => {
              const coverPhotoUrl = await PhotoService.coverPhotoUrl(event)
              return { ...event, coverPhotoUrl };
            })
          );

          setEvents(eventsWithPhotos);
      })
      .finally(() => setLoading(false));

  }, [isAuthenticated]);

  const goToSlideshow = (eventCode) => {
    navigate(`/slideshow/${eventCode}`);
  };

  return (
    <div className="events-container">
      <Header />
      
      <h2>Events</h2>
      {loading ? (
        <p>Loading events...</p>
      ) : events.length > 0 ? (
        <div className="horizontal-scroll">
          {events.map((event) => (
            <div key={event.eventCode} className="event-thumbnail" onClick={() => goToSlideshow(event.code)}>
              <img src={event.coverPhotoUrl} alt={event.title} />
              <p>{event.title}</p>
            </div>
          ))}
        </div>
      ) : (
        <p>No events found for this user.</p>
      )}
    </div>
  );
};

export default Events;
