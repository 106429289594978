// src/components/Photo.jsx
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const Photo = ({ url, driftX, driftY, rotation, zIndex, isAnimated }) => {
  const photoRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!isAnimated) return; // Only animate if the photo is set to be animated

    const photo = photoRef.current;
    if (!photo) return;

    let startTime;
    const duration = 6000; // Animation duration (6 seconds)
    const initialTransform = {
      x: 0,
      y: 0,
      rotation: 0,
    };

    const targetTransform = {
      x: parseFloat(driftX),
      y: parseFloat(driftY),
      rotation: parseFloat(rotation),
    };

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;
      const progress = Math.min(elapsedTime / duration, 1); // Ensure progress does not exceed 1

      // Calculate current position in vw/vh
      const currentX = initialTransform.x + (targetTransform.x - initialTransform.x) * progress;
      const currentY = initialTransform.y + (targetTransform.y - initialTransform.y) * progress;
      const currentRotation = initialTransform.rotation + (targetTransform.rotation - initialTransform.rotation) * progress;

      // Apply the calculated transform with vw/vh units
      photo.style.transform = `translate(calc(-50% + ${currentX}vw), calc(-50% + ${currentY}vh)) rotate(${currentRotation}deg)`;

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [driftX, driftY, rotation, isAnimated]);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <img
      ref={photoRef}
      src={url}
      onLoad={handleLoad}
      className={`slideshow-image ${isAnimated ? 'animated' : 'settled'}`}
      style={{
        zIndex,
        opacity: loaded ? 1 : 0,
        transform: isAnimated
          ? 'translate(-50%, -50%)' // Start centered if animated
          : `translate(calc(-50% + ${driftX}), calc(-50% + ${driftY})) rotate(${rotation})`, // Final position if settled
      }}
    />
  );
};

Photo.propTypes = {
  url: PropTypes.string.isRequired,
  driftX: PropTypes.string.isRequired,
  driftY: PropTypes.string.isRequired,
  rotation: PropTypes.string.isRequired,
  zIndex: PropTypes.number.isRequired,
  isAnimated: PropTypes.bool.isRequired,
};

export default Photo;
