
import { getAuth, signOut, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import '../firebaseConfig';

const AuthService = {
    signIn: async (email, password) => {
        const auth = getAuth();
       await signInWithEmailAndPassword(auth, email, password)
    },
    
    signOut: async () => {
        const auth = getAuth();
        await signOut(auth);
    },

    onAuthStateChange: (onUserChange) => {
        const auth = getAuth();
        return onAuthStateChanged(auth, (user) => {
            onUserChange(user);
        });
    },

    isLoggedIn: () => {
        const auth = getAuth();
        return !!auth.currentUser;
    },
    getUserId: () => {
        const auth = getAuth();
        return auth.currentUser.uid;
    }
};

export default AuthService;
