import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig';

const PhotoService = {

    coverPhotoUrl: async (event) => {
        if (event.coverPhotoId) {
            if (event.coverPhotoId.startsWith('stock photos/')) {
                // Stock cover photo path
                const coverPhotoRef = storageRef(storage, `${event.coverPhotoId}`);
                try {
                    return await getDownloadURL(coverPhotoRef);
                } catch (error) {
                    console.error('Error fetching stock cover photo:', error);
                }
            } else {
                // Custom cover photo path
                const coverPhotoRef = storageRef(storage, `events/${event.id}/${event.coverPhotoId}.jpeg`);
                try {
                    return await getDownloadURL(coverPhotoRef);
                } catch (error) {
                    console.error('Error fetching custom cover photo:', error);
                }
            }
        } else {
            // Default cover photo
            const coverPhotoRef = storageRef(storage, `stock photos/placeholder.png`);
            try {
                return await getDownloadURL(coverPhotoRef);
            } catch (error) {
                console.error('Error fetching stock cover photo:', error);
            }
        }
    },
    preloadImage: (url) => {
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve();
        });
    },
};

export default PhotoService;