import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login/Login';
import Slideshow from './Slideshow/Slideshow';
import Events from './Events/Events';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/events" element={<Events />} />
        <Route path="/slideshow/:eventCode" element={<Slideshow />} />
      </Routes>
    </Router>
  );
}

export default App;
